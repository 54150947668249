import { Box } from '@mui/material';
import cls from 'classnames';
import { useRouter } from 'next/router';
import Logo from 'public/assets/icons/logo.svg';
import useResponsive from '~hooks/useResponsive';
function ResponsiveLogo({
  sx
}: any) {
  const isMobile = useResponsive('down', 'md');
  const isMd1 = useResponsive('down', 'md1');
  const {
    push,
    back
  } = useRouter();
  const handleRedirection = () => {
    push('/');
  };
  return <Box className={cls('border-red-700 border-0', 'absolute')} onClick={handleRedirection} sx={{
    left: isMobile ? '22px' : isMd1 ? '3%' : '8%',
    top: isMobile ? '18px' : undefined,
    cursor: 'pointer',
    ...sx
  }} data-sentry-element="Box" data-sentry-component="ResponsiveLogo" data-sentry-source-file="ResponsiveLogo.tsx">
      {!isMobile && <Logo height={100} width={180} />}
    </Box>;
}
export default ResponsiveLogo;