import { Box } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { bgGradient } from '../../../config/utils/cssStyles';
import { IMAGES_PREFIX } from '~config/config-global';

export const StyledRoot = styled('main')(() => ({
  height: '100%',
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
}));

export const StyledSection = styled('div')(({ theme }) => ({
  display: 'none',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));

export const StyledSectionBg = styled('div')(({ theme }) => ({
  ...bgGradient({
    color: alpha(
      theme.palette.background.default,
      theme.palette.mode === 'light' ? 0.9 : 0.94
    ),
    imgUrl: IMAGES_PREFIX + '/assets/background/overlay_2.jpg',
  }),
  top: 0,
  left: 0,
  zIndex: -1,
  width: '100%',
  height: '100%',
  position: 'absolute',
  transform: 'scaleX(-1)',
}));

export const StyledContent = styled('div')(({ theme }) => ({
  width: '95%',
  maxWidth: '630px',
  // margin: 'auto',
  display: 'flex',
  minHeight: 'calc(100vh-30px)',
  justifyContent: 'flex-start',
  padding: theme.spacing('15px', '64px'),
  [theme.breakpoints.up('md')]: {
    flexShrink: 0,
  },
}));
