import { Box, Stack, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import Image from '~components/image';
import NeedHelp from '~components/need-help';
import ResponsiveLogo from '~components/responsive-logo';
import useResponsive from '~hooks/useResponsive';
import { StyledContent, StyledRoot, StyledSection, StyledSectionBg } from './styles';
import { IMAGES_PREFIX } from '~config/config-global';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState } from 'react';
type Props = {
  title?: string;
  illustration?: string;
  children: React.ReactNode;
};
export default function LoginLayout({
  children,
  illustration,
  title
}: Props) {
  const isMatched = useResponsive('up', 'lg');
  const {
    push,
    back
  } = useRouter();
  const [backOnce, setbackOnce] = useState(false);
  const handleRedirection = () => {};
  return <StyledRoot data-sentry-element="StyledRoot" data-sentry-component="LoginLayout" data-sentry-source-file="LoginLayout.tsx">
      <StyledSection sx={{
      border: '0px solid red',
      paddingBottom: '40px'
    }} data-sentry-element="StyledSection" data-sentry-source-file="LoginLayout.tsx">
        <Box sx={{
        height: '100px',
        border: '0px solid red'
      }} data-sentry-element="Box" data-sentry-source-file="LoginLayout.tsx">
          <ResponsiveLogo data-sentry-element="ResponsiveLogo" data-sentry-source-file="LoginLayout.tsx" />
        </Box>
        <Image disabledEffect visibleByDefault alt="auth" src={illustration ? illustration : IMAGES_PREFIX + '/assets/images/login-left.png'} imageStyles={{
        objectPosition: 'center top'
      }} sx={{
        px: '10px',
        border: '0px solid red',
        height: '812px'
      }} data-sentry-element="Image" data-sentry-source-file="LoginLayout.tsx" />
        <StyledSectionBg data-sentry-element="StyledSectionBg" data-sentry-source-file="LoginLayout.tsx" />
      </StyledSection>
      <StyledContent sx={{
      pt: '75px',
      border: '0px solid red',
      width: isMatched ? '50%' : undefined
    }} data-sentry-element="StyledContent" data-sentry-source-file="LoginLayout.tsx">
        <Stack sx={{
        width: 1
      }} data-sentry-element="Stack" data-sentry-source-file="LoginLayout.tsx">
          <NeedHelp data-sentry-element="NeedHelp" data-sentry-source-file="LoginLayout.tsx" />

          <IconButton sx={{
          position: 'absolute',
          top: '21px',
          left: '16px',
          cursor: 'pointer'
        }} onClick={() => {
          if (backOnce) {
            push('/');
          } else {
            setbackOnce(true);
            back();
          }
        }} data-sentry-element="IconButton" data-sentry-source-file="LoginLayout.tsx">
            <ArrowBackIcon data-sentry-element="ArrowBackIcon" data-sentry-source-file="LoginLayout.tsx" />
          </IconButton>

          {children}
        </Stack>
      </StyledContent>
    </StyledRoot>;
}