import { Typography } from '@mui/material';
import { support } from '~config/constants/paths';
function NeedHelp({}) {
  return <Typography onClick={() => window.open(support)} sx={{
    position: 'absolute',
    top: 21,
    right: 16,
    cursor: 'pointer'
  }} data-sentry-element="Typography" data-sentry-component="NeedHelp" data-sentry-source-file="NeedHelp.tsx">
      Need Help?
    </Typography>;
}
export default NeedHelp;